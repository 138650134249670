import $ from 'jquery'

export function answer() {
    if (window.location.pathname.includes('answer')) {
        const $balanceDirection = $('#balance-direction');
        const $balanceChange = $('#balance-change');
        const step = window.location.pathname.split('/')[2];

        let stepData, balanceDirection, balanceChange;
        if (localStorage.getItem('quiz-question-' + step + '-data') !== '') {
            stepData = JSON.parse(localStorage.getItem('quiz-question-' + step + '-data'));
            balanceDirection = (stepData.balanceChange >= 0)
                ? '+'
                : '-';
            balanceChange = (stepData.balanceChange >= 0)
                ? stepData.balanceChange.toLocaleString('en')
                : (-1 * stepData.balanceChange).toLocaleString('en');
        }

        $balanceDirection.text(balanceDirection);
        $balanceChange.html(balanceChange);
    }
}