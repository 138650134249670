import $ from 'jquery'

export function music() {
    const $trigger = $('.ui-music-button');

    if (!document.getElementById('music').paused)
        $trigger.removeClass('-off');
    else
        $trigger.addClass('-off');

    $trigger.on('click', function() {
        if (!$(this).hasClass('-off')) {
            document.getElementById('music').pause();
            $trigger.addClass('-off');
        } else {
            document.getElementById('music').play();
            $trigger.removeClass('-off');
        }
    });
}