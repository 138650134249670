exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-answer-1-correct-js": () => import("./../../../src/pages/answer/1/correct.js" /* webpackChunkName: "component---src-pages-answer-1-correct-js" */),
  "component---src-pages-answer-1-wrong-js": () => import("./../../../src/pages/answer/1/wrong.js" /* webpackChunkName: "component---src-pages-answer-1-wrong-js" */),
  "component---src-pages-answer-2-correct-js": () => import("./../../../src/pages/answer/2/correct.js" /* webpackChunkName: "component---src-pages-answer-2-correct-js" */),
  "component---src-pages-answer-2-wrong-js": () => import("./../../../src/pages/answer/2/wrong.js" /* webpackChunkName: "component---src-pages-answer-2-wrong-js" */),
  "component---src-pages-answer-3-correct-js": () => import("./../../../src/pages/answer/3/correct.js" /* webpackChunkName: "component---src-pages-answer-3-correct-js" */),
  "component---src-pages-answer-3-wrong-js": () => import("./../../../src/pages/answer/3/wrong.js" /* webpackChunkName: "component---src-pages-answer-3-wrong-js" */),
  "component---src-pages-answer-4-correct-js": () => import("./../../../src/pages/answer/4/correct.js" /* webpackChunkName: "component---src-pages-answer-4-correct-js" */),
  "component---src-pages-answer-4-wrong-js": () => import("./../../../src/pages/answer/4/wrong.js" /* webpackChunkName: "component---src-pages-answer-4-wrong-js" */),
  "component---src-pages-answer-5-correct-js": () => import("./../../../src/pages/answer/5/correct.js" /* webpackChunkName: "component---src-pages-answer-5-correct-js" */),
  "component---src-pages-answer-5-wrong-js": () => import("./../../../src/pages/answer/5/wrong.js" /* webpackChunkName: "component---src-pages-answer-5-wrong-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-myresults-js": () => import("./../../../src/pages/myresults.js" /* webpackChunkName: "component---src-pages-myresults-js" */),
  "component---src-pages-question-1-js": () => import("./../../../src/pages/question/1.js" /* webpackChunkName: "component---src-pages-question-1-js" */),
  "component---src-pages-question-2-js": () => import("./../../../src/pages/question/2.js" /* webpackChunkName: "component---src-pages-question-2-js" */),
  "component---src-pages-question-3-js": () => import("./../../../src/pages/question/3.js" /* webpackChunkName: "component---src-pages-question-3-js" */),
  "component---src-pages-question-4-js": () => import("./../../../src/pages/question/4.js" /* webpackChunkName: "component---src-pages-question-4-js" */),
  "component---src-pages-question-5-js": () => import("./../../../src/pages/question/5.js" /* webpackChunkName: "component---src-pages-question-5-js" */),
  "component---src-pages-results-js": () => import("./../../../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-pages-results-share-js": () => import("./../../../src/pages/results/share.js" /* webpackChunkName: "component---src-pages-results-share-js" */)
}

