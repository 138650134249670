import { loader } from './src/components/loader/loader-handler'
import { music } from './src/components/music/music-handler'
import { animatedShow } from './src/components/animated-show/animated-show-handler'
import { stepper } from './src/components/stepper/stepper-handler'
import { layout } from './src/components/layout/layout-handler'
import { form } from './src/components/form/form-handler'
import { resultsCalculator } from './src/components/calculator/calculator-handler'
import { answer } from './src/components/answer-layout/answer-layout-handler'
import { share } from './src/components/share/share-handler'
import { tutorial } from './src/components/tutorial/tutorial-handler'

export const onRouteUpdate = ({
    location
}) => {
    answer();
    loader();
    music();
    animatedShow();
    stepper();
    layout();
    form();
    share();
    resultsCalculator();
    tutorial();
}