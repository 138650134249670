import $ from 'jquery'
import { navigate } from 'gatsby'

export function stepper() {
    $('[data-to]').on('click', function() {
        $('[data-type="animated-show"]').each(function(){
            var
                $e = $(this),
                duration = $e.attr('data-duration'),
                delay = parseFloat($e.attr('data-delay')) * 1000;

            if (delay > 3) delay = 1;
            
            setTimeout(() => {
                $e
                    .css({
                        'transition': duration / 2 + 's'
                    })
                    .removeClass('-show');
            }, delay);
        });

        setTimeout(() => {
            navigate($(this).attr('data-to'));
        }, 500);
    });
}