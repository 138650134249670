import $ from 'jquery'
import copy from 'copy-to-clipboard'

export function share() {
    $('.react-share__ShareButton').each(function() {
        let
            $e = $(this),
            type = $e.attr('aria-label');

        $e.on('click', function() {
            $e.addClass('-shared');

            localStorage.setItem('quiz-' + type + '-shared', 'true');
        });
    });

    if (
        window.location.search &&
        (
            window.location.search === '?type=twitter' ||
            window.location.search === '?type=telegram' ||
            window.location.search === '?type=facebook'
        )
    ) {
        let type = window.location.search.replace('?type=', '');

        $('.react-share__ShareButton[aria-label="' + type + '"]').click();
    }

    let $button = $('#copy-to-clipboard');
    $button.on('click', function() {
        copy($('#copy-to-clipboard').closest('.form__controls').find('.form__controls__input').val());
        $button.addClass('-success');
        setTimeout(() => {
            $button.removeClass('-success');
        }, 2000);
    });
}