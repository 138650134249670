import $ from 'jquery'
import Settings from '../../settings'

export function animatedShow() {
    let timeout = 0;
    if (!$('html').hasClass('-loaded')) timeout = Settings.loaderTimeout;
    else timeout = 0;

    $('html, body').scrollTop(0);

    $(document).ready(() => {
        if (Settings.showAnimationsOn === true) {
            setTimeout(() => {
                $('[data-type="animated-show"]').each(function(){
                    var
                        $e = $(this),
                        duration = parseFloat($e.attr('data-duration')) / 3,
                        delay = parseFloat($e.attr('data-delay')) * 1000 / 3;
                    
                    setTimeout(() => {
                        $e
                            .css({
                                'transition': duration + 's'
                            })
                            .addClass('-show');
                    }, delay);
                });

                $('[data-count-type="count"]').each(function(){
                    var
                        $e = $(this),
                        delay = parseFloat($e.attr('data-count-delay')) * 1000 / 3,
                        $counter = $e.children('span'),
                        current = 0,
                        finish = parseFloat($e.attr('data-count-finish')),
                        step = finish / 20,
                        setNumber;

                    // console.log(finish);

                    $counter.text('');

                    if (!$e.attr('data-count-step')) {
                        setTimeout(() => {
                            setNumber = setInterval(() => {
                                current += step;
                                $counter.text(Math.floor(current).toLocaleString('en'));
                                if (current === finish)
                                    clearInterval(setNumber);
                            }, step / 100);
                        }, delay);
                    } else {
                        setTimeout(() => {
                            setNumber = setInterval(() => {
                                current += parseFloat($e.attr('data-count-step')) * 13;
                                $counter.text(Math.floor(current).toLocaleString('en'));
                                if (current >= finish)
                                    clearInterval(setNumber);
                            }, parseFloat($e.attr('data-count-step')));
                        }, delay);
                    }
                });
            }, timeout);
        } else {
            $('[data-type="animated-show"]').addClass('-show');
        }
    });
}