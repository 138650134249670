import $ from 'jquery'
import Settings from '../../settings.js'

export function tutorial() {
    if (
        window.location.pathname.includes('question/1') ||
        window.location.pathname.includes('answer/1')
    ) {
        setTimeout(() => {
            function showStep(stepNumber) {
                const timeout = Settings.tutorialStepInterval;
                const interval = 100;
                let stepsLength = 0;

                // Hide other steps
                $('.tutorial__item[data-step]').removeClass('-show');
                $('.tutorial__calculator').removeClass('-show');

                // Show current step
                $('.tutorial-overlay').addClass('-show');
                if (window.location.pathname.includes('question/1')) {
                    stepsLength = $('.tutorial[data-id="question-1"] .tutorial__item[data-step]').length;
                    $('.tutorial[data-id="question-1"]').addClass('-show');
                }
                else if (window.location.pathname.includes('answer/1/correct')) {
                    stepsLength = $('.tutorial[data-id="answer-1-correct"] .tutorial__item[data-step]').length;

                    $('.tutorial[data-id="answer-1-correct"] .tutorial__item[data-step="1"] .tutorial__item__content').html($('.app .ui-paragraph').html());
                    $('.tutorial[data-id="answer-1-correct"] [data-id="current-balance"]').html($('#current-balance').html());
                    $('.tutorial[data-id="answer-1-correct"] [data-id="balance-changed"]').html($('#balance-changed').html());

                    $('.tutorial[data-id="answer-1-correct"]').addClass('-show');
                }
                else if (window.location.pathname.includes('answer/1/wrong')) {
                    stepsLength = $('.tutorial[data-id="answer-1-wrong"] .tutorial__item[data-step]').length;

                    $('.tutorial[data-id="answer-1-wrong"] .tutorial__item[data-step="1"] .tutorial__item__content').html($('.app .ui-paragraph').html());
                    $('.tutorial[data-id="answer-1-wrong"] [data-id="current-balance"]').html($('#current-balance').html());
                    $('.tutorial[data-id="answer-1-wrong"] [data-id="balance-changed"]').html($('#balance-changed').html());

                    $('.tutorial[data-id="answer-1-wrong"]').addClass('-show');
                }

                $('.tutorial__item[data-step="' + stepNumber + '"]').addClass('-show');
                if (stepNumber > 2)
                    $('.tutorial__calculator').addClass('-show');
                else
                    $('.tutorial__calculator').removeClass('-show');

                // Positioning
                if ($(window).width() <= 768) {
                    setTimeout(function() {
                        $('.tutorial').each(function(){
                            $(this).css({
                                'margin-top': -$(this).outerHeight()
                            });
                        });
                    }, 100);
                }
        
                // Progress, hide current step, show next step
                let time = 0;
                let progress = 0;
                let progressInterval = setInterval(() => {
                    progress = time / timeout;
        
                    if (progress <= 0.5) {
                        $('.tutorial__item[data-step="' + stepNumber + '"] .tutorial__item__hint__footer__timer__progress.-right').css({
                            'clip': 'rect(0px, 40px, ' +  40 * (time / timeout * 2) + 'px, 20px)'
                        });
                        $('.tutorial__item[data-step="' + stepNumber + '"] .tutorial__item__hint__footer__timer__progress.-left').css({
                            'clip': 'rect(0px, 40px, 0px, 20px)'
                        });
                    } else {
                        $('.tutorial__item[data-step="' + stepNumber + '"] .tutorial__item__hint__footer__timer__progress.-right').css({
                            'clip': 'rect(0px, 40px, 40px, 20px)'
                        });
                        $('.tutorial__item[data-step="' + stepNumber + '"] .tutorial__item__hint__footer__timer__progress.-left').css({
                            'clip': 'rect(0px, 40px, ' +  40 * ((time / timeout - 0.5) * 2) + 'px, 20px)'
                        });
                    }
        
                    time += interval;
        
                    if (time >= timeout) {
                        clearInterval(progressInterval);
                        if (stepNumber + 1 <= stepsLength)
                            showStep(stepNumber + 1);
                        else if (stepNumber === stepsLength) {
                            $('.tutorial').removeClass('-show');
                            $('.tutorial-overlay').removeClass('-show');
                            $('.tutorial__item[data-step]').removeClass('-show');
                            $('.tutorial__calculator').removeClass('-show');
                        }
                    }
                }, interval);

                // Skip button
                $('.tutorial__item[data-step="' + stepNumber + '"] [data-action="skip"]').on('click', function() {
                    clearInterval(progressInterval);
                    $('.tutorial').removeClass('-show');
                    $('.tutorial-overlay').removeClass('-show');
                    $('.tutorial__item[data-step]').removeClass('-show');
                    $('.tutorial__calculator').removeClass('-show');
                });

                // Continue button
                $('.tutorial__item[data-step="' + stepNumber + '"] [data-action="continue"]').on('click', function() {
                    clearInterval(progressInterval);

                    let
                        $e = $(this),
                        currentStep = parseFloat($e.closest('.tutorial__item').attr('data-step')),
                        nextStep = currentStep + 1;

                    if (nextStep <= stepsLength)
                        showStep(nextStep);
                    else if (currentStep === stepsLength) {
                        clearInterval();
                        $('.tutorial').removeClass('-show');
                        $('.tutorial-overlay').removeClass('-show');
                        $('.tutorial__item[data-step]').removeClass('-show');
                        $('.tutorial__calculator').removeClass('-show');
                    }
                });
            }

            showStep(1);
        }, Settings.tutorialTimeout);
    }
}