import $ from 'jquery'
import Settings from '../../settings.js'
import { navigate } from 'gatsby'

export function form() {
    function formSubmit(
        $form,
        action,
        $input,
        $container
    ) {
        var reg_email = Settings.regEmail;

        // Remove the "wrong" style of the input
        $input.on('keydown', function () {
            $input.removeClass('-wrong');
        });

        // Wrong value
        if (reg_email.test($input.val()) === false)
            $input.addClass('-wrong').focus();

        // Sending
        else {
            $container.addClass('-loading');

            $.ajax({
                url: action,
                type: 'GET',
                data: {
                    'email': $input.val(),
                    'apiKey': Settings.sendGrid.apiKey,
                    'listId': Settings.sendGrid.listId
                },
                dataType: 'json',
                success: function (response) {
                    // Success
                    if (response === 1) {
                        $container.removeClass('-loading').addClass('-success');
                        setTimeout(() => {
                            navigate('/results/share');
                        }, 1000);
                    }
                    // Fields error
                    else
                        $container.removeClass('-loading').addClass('-error');
                },
                error: function () {
                    // Backend error
                    $container.removeClass('-loading').addClass('-error');
                }
            });
        }
    }

    $('#email-form').each(function () {
        var
            $form = $(this),
            action = $form.attr('action'),
            $input = $form.find('.form__controls__input'),
            $button = $form.find('.form__controls__button'),
            $container = $form.closest('.form');

        $form.on('submit', function (e) {
            e.preventDefault();
        });

        $button.on('click', function () {
            formSubmit(
                $form,
                action,
                $input,
                $container
            );
        });

        $input.on('keyup', function (key) {
            if (key.keyCode === 13) {
                formSubmit(
                    $form,
                    action,
                    $input,
                    $container
                );
            }
        });
    });
}