import $ from 'jquery'
import Settings from "../../settings.js"
import { navigate } from 'gatsby'

export function layout() {
    // Set default storage items
    if (
        !localStorage.getItem('quiz-start-balance') ||
        window.location.pathname === '/'
    ) {
        localStorage.setItem('quiz-start-balance', Settings.startBalance);
        localStorage.setItem('quiz-current-balance', Settings.startBalance);
        localStorage.setItem('quiz-question-1-data', null);
        localStorage.setItem('quiz-question-2-data', null);
        localStorage.setItem('quiz-question-3-data', null);
        localStorage.setItem('quiz-question-4-data', null);
        localStorage.setItem('quiz-question-5-data', null);
        localStorage.setItem('quiz-progress', '/');
        localStorage.setItem('quiz-completed', null);
        localStorage.setItem('quiz-user-id', null);
        localStorage.setItem('quiz-share-link', null);
        localStorage.setItem('quiz-twitter-shared', null);
        localStorage.setItem('quiz-telegram-shared', null);
        localStorage.setItem('quiz-facebook-shared', null);
    }

    // Redirect to current step or to the Results page
    if (
        !window.location.pathname.includes(localStorage.getItem('quiz-progress')) &&
        !window.location.pathname.includes('myresults') &&
        !window.location.pathname.includes('share')
    ) {
        navigate(localStorage.getItem('quiz-progress'));
    }
    if (
        localStorage.getItem('quiz-progress') === '/' &&
        window.location.pathname.length > 1 &&
        !window.location.pathname.includes('myresults')
    ) {
        navigate(localStorage.getItem('quiz-progress'));
    }
    
    // Confetti
    if ($('.screen__confetti').length > 0) {
        const Confettiful = function (el) {
            this.el = el;
            this.containerEl = null;

            this.confettiFrequency = 3;
            this.confettiColors = ['#fce18a', '#ff726d', '#b48def', '#f4306d'];
            this.confettiAnimations = ['slow', 'medium', 'fast'];

            this._setupElements();
            this._renderConfetti();
        };

        Confettiful.prototype._setupElements = function () {
            const containerEl = document.createElement('div');
            const elPosition = this.el.style.position;

            if (elPosition !== 'relative' || elPosition !== 'absolute') {
                this.el.style.position = 'relative';
            }

            containerEl.classList.add('confetti-container');

            this.el.appendChild(containerEl);

            this.containerEl = containerEl;
        };

        Confettiful.prototype._renderConfetti = function () {
            this.confettiInterval = setInterval(() => {
                const confettiEl = document.createElement('div');
                const confettiSize = (Math.floor(Math.random() * 3) + 7) + 'px';
                const confettiBackground = this.confettiColors[Math.floor(Math.random() * this.confettiColors.length)];
                const confettiLeft = (Math.floor(Math.random() * this.el.offsetWidth)) + 'px';
                const confettiAnimation = this.confettiAnimations[Math.floor(Math.random() * this.confettiAnimations.length)];

                confettiEl.classList.add('confetti', 'confetti--animation-' + confettiAnimation);
                confettiEl.style.left = confettiLeft;
                confettiEl.style.width = confettiSize;
                confettiEl.style.height = confettiSize;
                confettiEl.style.backgroundColor = confettiBackground;

                confettiEl.removeTimeout = setTimeout(function () {
                    confettiEl.parentNode.removeChild(confettiEl);
                }, 3000);

                this.containerEl.appendChild(confettiEl);
            }, 25);
        };

        window.confettiful = new Confettiful(document.querySelector('.js-container'));

        if (window.location.pathname.includes('answer')) {
            setTimeout(() => {
                $('.screen__confetti .confetti-container').addClass('-hidden');
            }, 10000);
        }
    }

    // Question 1 mobile advice (about swiping)
    if (
        Settings.mobileSwipeEnabled === true &&
        $('.mobile-advice__button').length > 0
    ) {
        let
            $button = $('.mobile-advice__button'),
            $container = $('body');

        $button.on('click', function () {
            $container.addClass('advice-hidden');
        });
    }

    // Swiper
    if (Settings.mobileSwipeEnabled === true) {
        $(document).off('touchstart');
        $(document).off('touchend');

        let isSwiping = $('#swiper').length > 0 && $(window).width() < 768;

        function swipeLeft() {
            if (!$('body').hasClass('-question-1')) {
                $('.screen').addClass('-moving-left');
                setTimeout(() => {
                    $('#buy').trigger('click');
                }, 250);
            } else if ($('body').hasClass('-question-1') && $('body').hasClass('advice-hidden')) {
                $('.screen').addClass('-moving-left');
                setTimeout(() => {
                    $('#buy').trigger('click');
                }, 250);
            }
        }

        function swipeRight() {
            if (!$('body').hasClass('-question-1')) {
                $('.screen').addClass('-moving-right');
                setTimeout(() => {
                    $('#sell').trigger('click');
                }, 250);
            } else if ($('body').hasClass('-question-1') && $('body').hasClass('advice-hidden')) {
                $('.screen').addClass('-moving-right');
                setTimeout(() => {
                    $('#sell').trigger('click');
                }, 250);
            }
        }

        let start;
        let factor = $(window).width() / 3;

        $(document).on('touchstart', function (e) {
            if (isSwiping)
                start = e.originalEvent.touches[0].clientX;
        });

        $(document).on('touchend', function (e) {
            if (isSwiping) {
                let end = e.originalEvent.changedTouches[0].clientX;

                if ($(e.target).closest('input[type="range"]').length === 0) {
                    if (start > end + factor) swipeLeft();
                    else if (start < end - factor) swipeRight();
                }
            }
        });
    }
}