import $ from 'jquery'

export function resultsCalculator() {
    // Calculator
    let
        $leverageSlider = $('.ui-slider__control input'),
        $depositRadio = $('input[type="radio"][name="deposit"]'),
        $power = $('#power');

    function powerCalc() {
        let deposit = $('input[type="radio"][name="deposit"]:checked').val();
        let leverage = $leverageSlider.val();
        $power.text('$' + (deposit * leverage).toLocaleString('en'));
    }

    function sliderEmptyWidth($input, $empty) {
        let value = $input.val();
        let min = $input.attr('min');
        let max = $input.attr('max');
        let progress = (value - min) * 100 / (max - min);
        let factor = 24 * (100 - progress) / 100;
        let left = 'calc(' + progress + '% + ' + factor + 'px)';
        $empty.css({
            'left': left
        });
    }

    $depositRadio.each(function(){
        $(this).on('change', powerCalc);
    });
    
    $leverageSlider.each(function(){
        let
            $e = $(this),
            $parent = $e.closest('.ui-slider__control'),
            $empty = $parent.find('.ui-slider__control__empty');

        sliderEmptyWidth($e, $empty);

        powerCalc();

        $e.on('input', function(){
            sliderEmptyWidth($e, $empty);

            powerCalc();
        });
    });

    // Results recording
    $('[data-calculator-button]').each(function(){
        let $e = $(this);
        let currentStep = parseFloat($(this).attr('data-current-step'));
        let correct = $e.attr('data-to').includes('correct');

        $e.on('click', function(){
            let deposit = $('input[type="radio"][name="deposit"]:checked').val();
            let leverage = $leverageSlider.val();
            let balanceChange = (correct) ? deposit * leverage : -deposit;
            let answerSlideBalance = (parseFloat(localStorage.getItem('quiz-current-balance')) + balanceChange);

            localStorage.setItem('quiz-current-balance', answerSlideBalance);
            localStorage.setItem('quiz-question-' + currentStep + '-data', JSON.stringify({
                deposit: deposit,
                leverage: leverage,
                power: deposit * leverage,
                balanceChange: balanceChange,
                correctAnswer: correct,
                type: $e.text()
            }));
        });
    });

    // Save current progress and avoid to back
    $('[data-to]').each(function(){
        let $e = $(this);
        let to = $e.attr('data-to');

        $e.on('click', function(){
            localStorage.setItem('quiz-progress', to);
        });
    });
}